import { getPolls, pollReset } from 'features/polls/pollSlice'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import PollItem from './PollItem' 
import { MobilePageTitle } from 'components/main.styled'
import { pollLanguage } from './pollLanguage'
import { NoItemsContainer } from 'components/main.styled'
import { MdOutlinePoll } from "react-icons/md";


export default function PollFeed() {
    const dispatch = useDispatch() 
    const { user } = useSelector((state) => state.auth)
    const { 
        polls, isPollsSuccess, isPollsLoading, hasNextPage, pageNumber, 
        // isPollsSuccess, 
        // isPollsError, 
        // pollErrorMessage,
        // totalPolls,
        // totalPages, 
        // hasPrevPage,
        // nextPage,
        // prevPage, 
        // hasSortValue, 
        // sortValue,

    } = useSelector((state) => state.poll)

    const { profileChurch } = useSelector((state) => state.profileChurch)

    useEffect(() => {
        if(profileChurch){
            dispatch(getPolls({
                id: profileChurch._id
            }))             
        } 
    }, []) 


    const handleLoadMore = () => {
        if(hasNextPage && !isPollsLoading){
            dispatch(getPolls({
                id: profileChurch._id,
                pageNumber: pageNumber + 1,
                //sort by date
                //sort by number of votes
            }))
        }
    }

    useEffect(() => {
        return () => {
          dispatch(pollReset())
        }
      }, [])


  return (
    <PollFeedContainer>



        {/* {
            polls.map((poll, index) => (
                    <PollItem key={poll._id} index={index} data={poll} />
                )
            )
        } */}

        { isPollsSuccess ?
            (
                polls.length > 0 ?
                (
                    polls.map((poll, index) => (
                        <PollItem 
                            key={poll._id} 
                            index={index} 
                            data={poll} 
                            />
                        )
                    )
                ) 
                : 
                (
                    <NoItemsContainer>
                        <MdOutlinePoll />
                        <div>
                            {
                                pollLanguage.noPolls[user.preferredLanguage]
                            }
                        </div>  
                    </NoItemsContainer>
                )
            )
            :
            (
                <NoItemsContainer>
                    <MobilePageTitle>Loading Polls...</MobilePageTitle>
                </NoItemsContainer>
            )
        }



        {
            hasNextPage && (
                <LoadPollsButton onClick={handleLoadMore}>See More Polls</LoadPollsButton>
            )
        }
    </PollFeedContainer>
  )
}

const PollFeedContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 25px;
    @media (max-width: 768px) {
        padding: 5px;
        width: 100%;
        flex-direction: column;
        margin-top: 0px;
    }
`

const LoadPollsButton = styled.div`
    padding: 5px 20px;
    background-color: white;
    border: 1px solid grey;
    outline: none;
    cursor: pointer;
    color: #414141;
    border-radius: 5px;
    margin-bottom: 20px;
    &:hover{
        background-color: #c3c3c3;
    }

`
