import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getVideos } from 'features/videos/videoSlice'
import VideoCard from './VideoCard'
import { NoItemsContainer } from 'components/main.styled'
import { HiVideoCameraSlash } from "react-icons/hi2";
import { mediaLanguage } from '../mediaLanguage'

export default function VideoFeed() {

    const dispatch = useDispatch()
    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { videos, videoSort, getVideosSuccess, getVideosLoading } = useSelector((state) => state.video)
    const { user } = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(getVideos({
            churchId: profileChurch._id,
            sort: videoSort
        }))
    }, [videoSort])

    return (
        <VideoFeedContainer> 
            {   getVideosSuccess ?
                (
                    videos.length > 0 ?
                    (
                        videos.map((video) => (
                            <VideoCard key={video._id} data={video} />
                        ))
                    )
                    :
                    (
                        <NoItemsContainer>
                            <HiVideoCameraSlash />
                            <div>
                                {
                                    mediaLanguage.noVideos[user.preferredLanguage]
                                }
                            </div>
                        </NoItemsContainer>
                    )
                )
                :
                (
                    <NoItemsContainer>
                        <HiVideoCameraSlash />
                        <p>{`No videos found`}</p>
                    </NoItemsContainer>
                )

            }

        </VideoFeedContainer>
    )
}

const VideoFeedContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`