import { createPoll, setCloseCreatePollModal, softPollReset } from 'features/polls/pollSlice'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import PollIcon from '@mui/icons-material/Poll';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { toast } from 'react-toastify'
import ClearIcon from '@mui/icons-material/Clear'; 
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { pollLanguage } from './pollLanguage';
import SpinnerSmall from 'components/SpinnerSmall';
import { MdDriveFileRenameOutline } from "react-icons/md";
import { SubmitButton } from 'components/giving/giving.styled';
import { FIH, FileUploadInput, FileInputWrap, ButttonWrap, FileUploadButton } from 'components/files/files.styled';


export default function CreatePollModal() {
    const dispatch = useDispatch()
    const [title, setTitle] = useState('')
    const [choices, setChoices] = useState([])
    const [currentChoice, setCurrentChoice] = useState('')
    const [choiceIndex, setChoiceIndex] = useState(0)
    const { isPollCreatedLoading, isPollCreatedSuccess } = useSelector((state) => state.poll)
    const { user } = useSelector((state) => state.auth)

    const handleSubmit = () => {

        if(title.length === 0){
            return toast.error(pollLanguage.toastErrorTitle[user.preferredLanguage])
        }
  
        if(choices.length < 2){
           return toast.error(pollLanguage.toastErrorAtLeastTwoChoices[user.preferredLanguage])
        }

        dispatch(createPoll({
            title: title,
            choices: JSON.stringify(choices)
        }))
    }

    const handleRemoveChoice = (choiceIndex) => {
        const filteredArray = choices.filter((choice, index) => index !== choiceIndex)
        setChoices(filteredArray)
    }


    const PollResponseItem = ({ choice, index }) => {
        return (
            <PRIC key={index}>
                <span> {choice} </span>
                <ClearChoice onClick={() => handleRemoveChoice(index)}>
                    < ClearIcon style={{fontSize: 'smaller'}} />
                </ClearChoice>
            </PRIC> 
        )
    }

    useEffect(() => {
        if(isPollCreatedSuccess){

            dispatch(setCloseCreatePollModal())
            toast.success(pollLanguage.toastSuccess[user.preferredLanguage])
            dispatch(softPollReset())

        }
    },[choices, isPollCreatedSuccess, dispatch])


    const handleAddChoiceItem = () => {
        if(currentChoice.length === 0){ return toast.error(pollLanguage.toastErrorAddSomething[user.preferredLanguage]) }
        choices.push( currentChoice )
        setCurrentChoice('')
        setChoiceIndex(choiceIndex+1)
    }

    return (
        <PollModalWrap>                
            <CreatePollFormTop>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> 
                    <PollIcon style={{  marginLeft: '10px', marginRight: '10px'}} />  
                    <PollModalHeading>
                        {
                            pollLanguage.createPoll[user.preferredLanguage]
                        }
                    </PollModalHeading>
                </div>
                <ModalCloseButton
                    handler={() => dispatch(setCloseCreatePollModal())}
                    top='10px'
                    right='10px'
                    size='30px'
                    />
            </CreatePollFormTop>
            <CreatePollForm>
                <PollFormWrap> 
                    <PollResponsesWrap>
                        <PollQuestion>
                            <MdDriveFileRenameOutline style={{color: 'grey', fontSize: '20px', marginRight: '10px'}} />
                            <input 
                                value={title} 
                                placeholder={pollLanguage.pollTitle[user.preferredLanguage] }
                                onChange={(e) => setTitle(e.target.value)} 
                                />
                        </PollQuestion>
                        {
                            (choices.length < 10 ) && (
                                    <PollInput>
                                        <input 
                                            value={currentChoice} 
                                            placeholder={
                                                pollLanguage.addResponseOption[user.preferredLanguage]
                                                +
                                                ` #${choiceIndex+1}`
                                            }
                                            onChange={(e) => setCurrentChoice(e.target.value)} 
                                            />
                                        <button onClick={handleAddChoiceItem}>
                                            <span>
                                                {
                                                    pollLanguage.add[user.preferredLanguage]
                                                }
                                            </span> 
                                            <PlaylistAddIcon style={{marginLeft: '5px', color: 'white'}}/> 
                                        </button>
                                    </PollInput>
                            )
                        }
                        {   
                                choices.map((item, index) => (
                                        <PollResponseItem choice={item} index={index} />
                                    )
                                )
                        }

                    </PollResponsesWrap>
                </PollFormWrap>
                <ButttonWrap
                    justify='center'
                    >
                    <FileUploadButton 
                        onClick={handleSubmit}
                        disabled={isPollCreatedLoading}
                        style={{marginBottom: '20px'}}
                        width={'250px'}
                        >
                        { isPollCreatedLoading ? 
                            (
                                <SpinnerSmall />
                            )
                            :
                            (
                                pollLanguage.createButton[user.preferredLanguage]
                            )
                        }
                    </FileUploadButton>
                </ButttonWrap>
            </CreatePollForm>
        </PollModalWrap>
    )
}


const CreatePollForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
`

const PollFormWrap = styled.div`
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    width: 100%;
`
const PollModalWrap = styled.div`
  z-index: 0;
  width: 550px;
  min-height: 550px;
  max-height: 600px;
  overflow-y: scroll;

  /* remove scroll bar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    &::-webkit-scrollbar { /* WebKit */
        width: 0;   
        height: 0;
    }       


  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  border-radius: 2px;
  position: relative;
  animation: animate 0.3s;
  padding-bottom: 10px;
  @media (max-width: 768px) {
        align-self: center;
        justify-self: center;
        width: 95%;
        height: 70%;
    }
`


const PollQuestion = styled.div`
    width: 85%;
    border: none;
    border-bottom: 1px solid grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
    margin-top: 20px;
    @media (max-width: 768px) {
        margin-top: 10px;
        width: 90%;
    }
    & input{
        border-radius: 5px;

        padding: 10px;
        width: 100%;
        outline: none;
        border: none;
    }
`
const PollInput = styled.div`
    width: 85%;
    border: 1px solid var(--themeColor);
    margin-bottom: 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
        width: 90%;
    }
    & input{
        border-radius: 5px;

        padding: 10px;
        width: 100%;
        outline: none;
        border: none;
    }
    & button{
        background-color: var(--themeColor);
        color: white;
        padding: 10px;
        border: none;
        outline: none;
        border-radius: 0px 5px 5px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer
    }
`
const PollResponsesWrap = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const PRIC = styled.div`
    
    padding: 12px;
    background-color: #e6e6e6;
    width: 85%;
    color: #505050;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
        width: 90%;
        margin-bottom: 5px;
    }

`
const ClearChoice = styled.div`
    display: flex;
    padding: 3px;
    border: 1px solid lightgrey;
    align-items: center;
    cursor: pointer;
    &:hover{
        background-color: lightgray;
    }
`

const CreatePollBtn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border:1px solid grey; 
    border-radius: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 85%;
    color: grey;
    cursor: pointer;
    transition: .4s;
    pointer-events: ${props => props.disabled ? 'none':'all'};
    &:hover{
        background-color: var(--themeColor);
        color: white;
        border: 1px solid var(--themeColor);
    }
    @media (max-width: 768px) {
        width:90%;
    }
`

const CreatePollFormTop = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--lightThemeFontColor);
    padding-left: 8px;
    padding-top: 10px;
    justify-content: space-between;  
    border-radius: 2px;
`

const PollModalHeading = styled.div`
  font-size: 14px;
  font-weight: 100;
  /* padding-top: 15px;
  margin-bottom: 15px; */
  @media (max-width: 768px) {
    font-size: 14px;
    padding-top: 0px;
    /* margin-top: -15px; */
  }
`