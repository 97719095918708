import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setViewBulletinFile, setViewModal2DeleteBulletinFile } from 'features/bulletin/bulletinSlice'
import LaunchIcon from '@mui/icons-material/Launch';
import ArticleIcon from '@mui/icons-material/Article'; 
import { useSelector } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice';
import { deleteBulletin } from 'features/bulletin/bulletinSlice';

export default function BulletinItem(props) {
    const { user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const [c2V, setC2V] = useState(false)

    const handleClick = () => {

        dispatch(setViewBulletinFile(props.data.path))
    }

    const deleteBulletinFile = () => {
        dispatch(deleteBulletin({
            index: props.index,
            id: props.data._id,
            fileName: props.data.fileName
        }))
    }

    const handleDelete = () => {

        dispatch(setConfirmModalData({ 
            text: `Are you sure you want to delete ${props.data.fileName}?`,
            handler: deleteBulletinFile,
            confirmBtnBackgroundHover: 'red',
        }))

        dispatch(setViewConfirmModal(true))

    }



  return (

    <MainCont onMouseEnter={() => setC2V(true)} onMouseLeave={() => setC2V(false)} >

        <BulletIconWrap>
            <ArticleIcon />
        </BulletIconWrap>
        

        <BulletinItemContainer onClick={handleClick} >
            <BIInfo>
                { props.data.fileName } 
            </BIInfo>
            
                { c2V && (
                        <BIC2V>
                            <LaunchIcon />
                        </BIC2V>
                    )
                }

            <BIDetails>
                {props.data.fileSize}
            </BIDetails>
 
        </BulletinItemContainer>

        { user && 
            (
                ((user.isOrganization || props.isUserAdmin) && c2V) && (
                    <DeleteBulletin onClick={handleDelete}>
                        <DeleteForeverIcon />
                    </DeleteBulletin>
                )
            )
        }
    </MainCont>
  )
}
const MainCont = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 768px) { 
        width: 100%; 
    }

`

const BulletIconWrap = styled.div`
    color: #797979;
    margin-right: 10px;
    @media (max-width: 800px) {

        display: none;

    }

`

const BulletinItemContainer = styled.div`
    transition: 0.5s;
    padding: 20px;
    position: relative;
    background-color: white;
    width: 400px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px ;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 10px #ccc;

    @media (max-width: 768px) {
        width: 100%;
    }

`
const BIInfo = styled.div`
    color: var(--lightThemeFontColor);

`

const BIC2V = styled.div`
    position: absolute;
    color: grey;
    top: 5px;
    right: 32px;
    font-size: smaller;
    display: flex;
    flex-direction: row;
    align-items: center;


`

const BIDetails = styled.div`
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: smaller;
    color: grey;
    font-style: italic;
`
const DeleteBulletin = styled.div`
    margin-left: 15px;
    padding: 5px;
    color: #888888;
    /* border: 1px solid #ff00009d; */
    border-radius: 4px;
    background-color: none;
    cursor: pointer;
    &:hover{
        background-color: red;
        border: 2px solid red;
        color: white
    }
`