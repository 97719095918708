import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { imgUrl } from 'features/constants'
import EventMap from './EventMap'
import { eventLanguage } from '../eventsLanguage' 
import { toggle24hr } from 'funcs/timeFormat24hr'

export default function Details() {

  const { user } = useSelector((state) => state.auth)
  const { singlePageEventData, isSingleEventLoading } = useSelector((state) => state.events)
  const [coordinates, setCoordinates] = useState(null)
  const [totalAttending, setTotalAttending] = useState(singlePageEventData.attending.length)

  useEffect(() => {
    if(singlePageEventData.coordinates){
      setCoordinates([singlePageEventData.coordinates.lat.toString(), singlePageEventData.coordinates.lng.toString()])
    }
  }, [singlePageEventData.coordinates])

  useEffect(() => {
    // update the number of people attending by looping through the attending array
    // and adding the attending number of each user
    if(singlePageEventData && singlePageEventData.attending){
        let total = 0
        singlePageEventData.attending.forEach((item) => {
            total += item.attending
        })

        setTotalAttending(total)

        
    }
}, [singlePageEventData])

    return (
      <DetailsContainer>
  
        {
          singlePageEventData.allowRsvp && (
            <EventDetailsTop>
              <div>
                {totalAttending ? totalAttending:singlePageEventData.attending.length} 
                {eventLanguage.attending[user ? user.preferredLanguage : 'en']}
              </div>   
            </EventDetailsTop>            
          )
        }    


        <EventDescription>
          <EventDescriptionTitle>About this event</EventDescriptionTitle>
          <EventDescriptionText
            hasMap={coordinates ? true : false}
            > 

            <TimeAndDate> 
                {
                  singlePageEventData.startDate && 
                  <>
                    <span style={{marginBottom: '5px'}}>
                      <b style={{marginRight: '5px'}}>Starts:</b>  
                      {new Date(singlePageEventData.startDate).toLocaleString('default', { month: 'long' }) + ' ' + new Date(singlePageEventData.startDate).getDate() + ', ' + new Date(singlePageEventData.startDate).getFullYear()}
                      
                      
                      {
                        singlePageEventData.startTime && 
                        <span> 
                          <b> at </b> {toggle24hr(singlePageEventData.startTime, false)}
                        </span>
                      }
                    </span>
                    <span>
                      
                      
                      
                      {
                        singlePageEventData.endDate && 

                        <>
                          <b style={{marginRight: '5px'}}>Ends:</b>  
                          {new Date(singlePageEventData.endDate).toLocaleString('default', { month: 'long' }) + ' ' + new Date(singlePageEventData.endDate).getDate() + ', ' + new Date(singlePageEventData.endDate).getFullYear()}
                           
                           { singlePageEventData.endTime &&
                            <span>
                              {/* convert 24 hr clock time format to 12hr */}
                              <b> at </b> {toggle24hr(singlePageEventData.endTime, false)}
                            </span>         
                          }               
                        </>

                      }
                    </span>
                  </>
                }  
            </TimeAndDate>


            {singlePageEventData.description.split('\n').map((subtext, index) => (
              <span
                style={{
                  display: 'flex', 
                  marginTop: '10px'
                }}
                >
                  {subtext}  
              </span>
            ))} 
          </EventDescriptionText>

 

        </EventDescription>
      </DetailsContainer>
    )
}

const TimeAndDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  background-color: aliceblue ;
  color: #898989;
  border-radius: 10px;
  padding: 15px;
  height: fit-content;
  width: 100%;
  box-shadow: 0px 0px 5px #ababab;
`

const EventDescriptionTitle = styled.div`

  font-size: 13px;
  font-weight: 500;
  color: #ccc;
  margin: 10px 0px 10px 10px;
`


const DetailsContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 15px;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  @media (max-width: 768px) {
    height: fit-content;
  }
`
const EventDetailsTop = styled.div`
  width: 100%;
  height: fit-content;
  padding: 10px;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--lightThemeFontColor);
`

const EventDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: fit-content; 
  margin-top: 10px; 
  width: 100%;
`

const EventDescriptionText = styled.div`
  /* height: ${props => props.hasMap ? '50%' : '100%'}; */
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  color: var(--lightThemeFontColor);
  margin-bottom: 40px;
  padding: 10px 10px 20px 10px;
  width: 100%;
`

const MapContainer = styled.div`
  position: relative;
  min-height: 200px;
  height: 50%;
  width: 100%;
  margin-top: 10px;
  border-radius: 15px; 
  border: 1px solid #d6d6d6;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
`

const OpenInGoogle = styled.button` 
  position: absolute;
  top: 5px;
  border: 1px solid #b0b0b0;
  outline: none;
  border-radius: 3px;
  background-color: white;
  right: 5px;
  z-index: 999;
  color: #666666;
  padding: 5px;
  cursor: pointer;
  font-size: 11px;
`