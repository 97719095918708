import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import conversationService from './conversationService'

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

const initialState = {
  conversations: null,

  viewConversation: {},
  viewAllUsers: false,

  otherMemberConversations: [],
  
  deleteConversationLoading: false,
  deleteConversationError: false,
  deleteConversationSuccess: false,

  alreadyExists: false,
  alreadyExistsId: '',
  newConversation: '',

  isConversationsError: false,
  isConversationsSuccess: false,
  isConversationsLoading: false,
  conversationsErrorMessage: '',

  isConversations2Error: false,
  isConversations2Success: false,
  isConversations2Loading: false,
  conversations2ErrorMessage: '',

  currentConversation: '',
  currentMessagePanel: {
    id: null,
    image: null,
    name: null,
  },

  viewStartConversationModal: false,

  isCreateConversationError: false,
  isCreateConversationSuccess: false,
  isCreateConversationLoading: false,

  conversationType: '',

  isSearchUsersError: false,
  isSearchUsersSuccess: false,
  isSearchUsersLoading: false,
  searchUsersErrorMessage: '',

  users: [],

  church: null, // the church of the user should always be included in when getting the church members
  churches: [] , 

  isGetChurchMembersLoading: false,
  isGetChurchMembersError: false,
  isGetChurchMembersSuccess: false,
  getChurchMembersErrorMessage: '',

  searchUserResults: [],
  isSearchLoading: false,
  isSearchSuccess: false,
  isSearchError: false,

  totalMembers: 0,
  totalPages: 0,
  hasPrevPage: false,
  nextPage:null,
  currentPage: 1,
  prevPage: null,  
  hasNextPage: false,

  conversationsMessage: '',
}

export const createConversation = createAsyncThunk(
  'conversations/createConversation',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await conversationService.createConversation(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getConversations = createAsyncThunk(
  'conversations/getConversations',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await conversationService.getConversations(token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const searchUsers = createAsyncThunk(
  'conversations/searchUsers',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await conversationService.searchUsers(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getConversationsWithOtherMembers = createAsyncThunk(
  'conversations/getConversationsWithOtherMembers',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await conversationService.getConversationsWithOtherMembers(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getChurchMembers = createAsyncThunk(
  'conversations/getChurchMembers',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await conversationService.getChurchMembers(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const searchMembers = createAsyncThunk(
  'conversations/searchMembers',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await conversationService.searchMembers(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteConversation = createAsyncThunk(
  'conversations/deleteConversation',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await conversationService.deleteConversation(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const cleanupConversations = createAsyncThunk(
  'conversations/cleanupConversations',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await conversationService.cleanupConversations(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const conversationSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    conversationReset: (state) => initialState,

    chooseConversation: (state, action) => {
      state.viewConversation = action.payload
    },

    setViewAllUsers: (state, action) => {
      state.viewAllUsers = action.payload
    },

    resetAlreadyExists: (state) => {
      state.alreadyExists = false
      state.alreadyExistsId = ''
    },

    resetDeleteData: (state) => { 
      state.deleteConversationSuccess = false
      state.deleteConversationLoading = false
    },

    viewNoConversation: (state) => {
      state.viewConversation = { id: null, fullName: null }
      // state.viewAllUsers = false
    },
    setPanelInfo: (state, action) => {
      state.currentMessagePanel.id = action.payload.id
      state.currentMessagePanel.image = action.payload.image
      state.currentMessagePanel.name = action.payload.name
    },
    setViewStartConversationModal: (state, action) => {
      state.viewStartConversationModal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConversations.pending, (state) => {
        state.isConversationsLoading = true
      })
      .addCase(getConversations.fulfilled, (state, action) => {
        state.isConversationsError = false
        state.isConversationsSuccess = true
        state.isConversationsLoading = false
        state.conversations = action.payload
      })
      .addCase(getConversations.rejected, (state, action) => {
        state.isConversationsError = true
        state.isConversationsSuccess = false
        state.isConversationsLoading = false
        state.conversationsErrorMessage = action.payload
      })
      // -----------------------------------------------------------------------
      .addCase(createConversation.pending, (state) => {
        state.isCreateConversationLoading = true
        state.isCreateConversationSuccess = false
      })
      .addCase(createConversation.fulfilled, (state, action) => {
        state.isCreateConversationError = false
        state.isCreateConversationSuccess = true
        state.isCreateConversationLoading = false

        if (action.payload.exists) {

          state.alreadyExists = true
          state.alreadyExistsId = action.payload.conversationId
          state.conversationType = action.payload.conversationType
        
          if(action.payload.wasPreviouslyHidden){

            if(action.payload.conversationType === 'm2c'){
              state.conversations.unshift(action.payload.previouslyHiddenConversationData)
            }else if(action.payload.conversationType === 'm2m'){
              state.otherMemberConversations.unshift(action.payload.previouslyHiddenConversationData)
            }
          }

        } else {

          state.alreadyExists = false
          state.alreadyExistsId = ''
          state.newConversation = action.payload.conversation._id


          if(action.payload.conversationType === 'm2m'){

            state.conversationType = 'm2m'
            state.otherMemberConversations.unshift(action.payload.conversation)
            state.newConversation = action.payload.conversation._id

          }else if(action.payload.conversationType === 'm2c'){
            
            state.conversationType = 'm2c'
            state.conversations.unshift(action.payload.conversation)
            state.newConversation = action.payload.conversation._id
          
          }
        }
      })
      .addCase(createConversation.rejected, (state, action) => {
        state.isCreateConversationError = true
        state.isCreateConversationSuccess = false
        state.isCreateConversationLoading = false
        state.conversationsMessage = action.payload
      })
      //--------------------------------------------------------
      .addCase(searchUsers.pending, (state) => {
        state.isSearchUsersLoading = true
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.isSearchUsersError = false
        state.isSearchUsersSuccess = true
        state.isSearchUsersLoading = false
        state.searchUserResults = action.payload
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.isSearchUsersError = true
        state.isSearchUsersSuccess = false
        state.isSearchUsersLoading = false
        state.searchUsersErrorMessage = action.payload
      })
    // -----------------------------------------------------------------------
    .addCase(getConversationsWithOtherMembers.pending, (state) => {
      state.isConversations2Loading = true
    })
    .addCase(getConversationsWithOtherMembers.fulfilled, (state, action) => {
      state.isConversations2Error = false
      state.isConversations2Success = true
      state.isConversations2Loading = false
      state.otherMemberConversations = action.payload
    })
    .addCase(getConversationsWithOtherMembers.rejected, (state, action) => {
      state.isConversations2Error = true
      state.isConversations2Success = false
      state.isConversations2Loading = false
      state.conversations2ErrorMessage = action.payload
    })
    // -----------------------------------------------------------------------
    .addCase(getChurchMembers.pending, (state) => {
      state.isGetChurchMembersLoading = true
    })
    .addCase(getChurchMembers.fulfilled, (state, action) => {
      state.isGetChurchMembersError = false
      state.isGetChurchMembersSuccess = true
      state.isGetChurchMembersLoading = false
      state.churches = action.payload.churches

      if(action.payload.pageNumber === 1) {
        state.users = action.payload.users
      }else{
        state.users.push(...action.payload.users)
      }

      state.totalMembers = action.payload.totalMembers
      state.totalPages = action.payload.totalPages
      state.pageNumber = action.payload.pageNumber
      state.hasPrevPage = action.payload.hasPrevPage
      state.hasNextPage = action.payload.hasNextPage
      state.prevPage = action.payload.prevPage
      state.nextPage = action.payload.nextPage


    })
    .addCase(getChurchMembers.rejected, (state, action) => {
      state.isGetChurchMembersError = true
      state.isGetChurchMembersSuccess = false
      state.isGetChurchMembersLoading = false
      state.getChurchMembersErrorMessage = action.payload
    })
    // -----------------------------------------------------------------------
    .addCase(searchMembers.pending, (state) => {
      state.isSearchLoading = true
    })
    .addCase(searchMembers.fulfilled, (state, action) => {
      state.isSearchError = false
      state.isSearchSuccess = true
      state.isSearchLoading = false

      state.searchUserResults = action.payload.searchResults

      // state.users = action.payload.users
      // state.totalMembers = action.payload.totalMembers
      // state.totalPages = action.payload.totalPages
      // state.pageNumber = action.payload.pageNumber
      // state.hasPrevPage = action.payload.hasPrevPage
      // state.hasNextPage = action.payload.hasNextPage
      // state.prevPage = action.payload.prevPage
      // state.nextPage = action.payload.nextPage
    })
    .addCase(searchMembers.rejected, (state, action) => {
      state.isSearchError = true
      state.isSearchSuccess = false
      state.isSearchLoading = false
      state.searchUsersErrorMessage = action.payload
    })
    // -----------------------------------------------------------------------
    .addCase(deleteConversation.pending, (state) => {
      state.deleteConversationLoading = true
    })
    .addCase(deleteConversation.fulfilled, (state, action) => {
      state.deleteConversationError = false
      state.deleteConversationSuccess = true
      state.deleteConversationLoading = false

      if(action.payload.conversationType === 'm2m'){
        state.otherMemberConversations = state.otherMemberConversations.filter(conversation => conversation._id !== action.payload.conversationId)
      }else if(action.payload.conversationType === 'm2c'){
        state.conversations = state.conversations.filter(conversation => conversation._id !== action.payload.conversationId)
      }
    })
    .addCase(deleteConversation.rejected, (state, action) => {
      state.deleteConversationError = true
      state.deleteConversationSuccess = false
      state.deleteConversationLoading = false 
    }
    )
  },
})

export const { 
  setPanelInfo, 
  conversationReset, 
  chooseConversation, 
  viewNoConversation, 
  setViewAllUsers, 
  resetAlreadyExists,
  setViewStartConversationModal,
  resetDeleteData
} = conversationSlice.actions
export default conversationSlice.reducer