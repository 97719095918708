import React, {useEffect, useRef }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components' 
import { useNavigate, useLocation } from 'react-router-dom'
import { addAc } from 'features/auth/authSlice'
import { addAcFunc } from 'funcs/ac'
import Audio from './audio/Audio'
import Videos from './video/Videos'
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'; 
import useToggleDropdown from 'hooks/useToggleDropdown'
import { DotEle, DotsWrap } from 'components/dots/dots.styled';  
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import { setViewUploadAudioModal } from 'features/audio/audioSlice'
import { setViewUploadVideoModal } from 'features/videos/videoSlice'

export default function MediaPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const view = new URLSearchParams(location.search).get('view') ? new URLSearchParams(location.search).get('view') : 'none'
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)
    const handleDropDown = () => { setIsDropped.toggle(ref1) }

    const handleNavigate = (section) => { 
        localStorage.setItem('mediaView', section) 
        navigate(`?section=media&view=${section}`)
    }

    useEffect(() => {
        const dis = () => dispatch(addAc({
            type: 'videos', 
        }))
        addAcFunc('vd', dis)
    }, [])


    useEffect(() => {
        let currentView = localStorage.getItem('mediaView')
        if(!currentView){
            localStorage.setItem('mediaView', 'videos')
            navigate(`?section=media&view=videos`)
        }else{
            navigate(`?section=media&view=${currentView}`)
        }
    }, [])
        
    const handleUploadAudio = () => {
        dispatch(setViewUploadAudioModal(true))
        navigate(`?section=media&view=audio`)
    }

    const handleUploadVideo = () => {
        dispatch(setViewUploadVideoModal(true))
        navigate(`?section=media&view=videos`)
    }


    return (
        <MediaPageContainer> 
            <ButtonWrap>
                <MediaButton
                    active={view === 'videos' ? true : false}
                    onClick={() => handleNavigate('videos')}
                    >
                    <PlayCircleOutlineIcon />
                    <span>Videos</span>
                </MediaButton>
                <MediaButton
                    active={view === 'audio' ? true : false}
                    onClick={() => handleNavigate('audio')}
                    >
                    <GraphicEqIcon />
                    <span>Audio</span>
                </MediaButton> 
                <MediaMenu
                    ref={ref1}
                    onClick={handleDropDown}
                    >
                    <MoreHorizIcon />
                </MediaMenu>
                { 
                    isDropped && 
                    (
                        <DotsWrap 
                            top='30px'
                            right='10px'
                            >
                            <DotEle
                                onClick={handleUploadVideo}
                                >
                                <PlayCircleOutlineIcon style={{marginRight: '10px'}}/>
                                Upload Video
                            </DotEle>
                            <DotEle
                                onClick={handleUploadAudio}
                                >
                                <GraphicEqIcon style={{marginRight: '10px'}}/>
                                Upload Audio 
                            </DotEle>
                            {/* <DotEle>
                                <ViewHeadlineIcon style={{marginRight: '10px'}}/>
                                View as list
                            </DotEle>  */}
                        </DotsWrap>
                    )
                } 
            </ButtonWrap> 
            {
                view === 'audio' ? <Audio /> :  
                view === 'videos' && <Videos />
            } 
        </MediaPageContainer>
    )
}

const MediaPageContainer = styled.div`
    height: 100%;
    padding: 10px;
`
const MediaMenu = styled.div`
    position: absolute; 
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
        background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    svg{ 
        color: var(--lightThemeFontColor); 
    }

`
const ButtonWrap = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  

`

const MediaButton = styled.button`
    pointer-events: ${props => props.active ? 'none' : 'auto'};
    background-color: ${props => props.active ? 'var(--themeColor)' : 'white'};
    border: none;
    box-shadow: 0px 0px 5px #ccc;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
    color: ${props => props.active ? 'white' : 'var(--lightThemeFontColor);'};
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;

    &:hover{
        background-color: #e1e1e1;
    }

    svg{
        margin-right: 10px;
    }

`