import {useState,useRef} from 'react'
import styled from 'styled-components'
import useToggleDropdown from '../../hooks/useToggleDropdown';
import { useNavigate } from 'react-router-dom';
import { sendResponse, saveEvent, deleteEvent, setViewEventModalEdit, setSinglePageEventData } from '../../features/events/eventSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { BsThreeDots } from 'react-icons/bs' 
import { imgUrl } from 'features/constants';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit'; 
import CheckIcon from '@mui/icons-material/Check';
import { setConfirmModalData, setViewConfirmModal } from 'features/modal/modalSlice';
import useImageLoader from 'hooks/useImageLoader';
import { Dots, DotsButton, DotsWrap, DotEle } from 'components/dots/dots.styled'

export default function EventItem(props) {
    const navigate = useNavigate()
    const ref1 = useRef(null)
    const [isDropped, setIsDropped] = useToggleDropdown(ref1)

    const { profileChurch } = useSelector((state) => state.profileChurch)
    const { admin, moderator } = useSelector((state) => state.auth)
    const handleDropDown = (e) => { 
        e.stopPropagation()
        setIsDropped.toggle(ref1) 
    }
    const [isEventHovered, setIsEventHovered] = useState(false)
    const dotsRef = useRef(null)
    const [isDotsDropped, setIsDotsDropped] = useToggleDropdown(dotsRef)

    const handleDotsDropdown = (e) => {
        e.stopPropagation()
        setIsDotsDropped.toggle(dotsRef) 
    }
 
    const dispatch = useDispatch()
    const [isInterested, setIsInterested] = useState()
    const [isAttending, setIsAttending] = useState()
    const { user } = useSelector((state) => state.auth)
    const { isResponseLoading, isEventDeleted } = useSelector((state) => state.events)

    useEffect(() => {
        if(props.data.interested.find(ele => ele._id === user._id)){
            setIsInterested(true)
        }else(setIsInterested(false))
        if(props.data.attending.find(ele => ele._id === user._id)){
            setIsAttending(true)
        }else(setIsAttending(false))
    }, [props.data.attending, props.data.interested, user._id])


    const [imageSource, { blur }] = useImageLoader(
        `${imgUrl}/events/${props.data.image.imagePath1}`, 
        `${imgUrl}/events/${props.data.image.imagePath2}`)

    let theEventDate = new Date(props.data.startDate)
    let eventEndDate;
    if(props.data.endDate){
        eventEndDate = new Date(props.data.endDate)
    }

    let startTime = theEventDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

    const handleResponse = (e, myResponse) => {
        e.stopPropagation()
        setIsDropped.toggle(ref1)
        dispatch(sendResponse(
            {
                index: props.index,
                eventId: props.data._id,
                response: myResponse
            }))
    }

    const handleDelete = () => { 
        dispatch(setConfirmModalData({
            text: 'Are you sure you want to delete this event? This action cannot be undone',
            handler: () => dispatch(deleteEvent({
                index: props.index,
                eventId: props.data._id,
                churchId: props.data.churchId,
            }))
        }))

        dispatch(setViewConfirmModal(true))
    }

    useEffect(() => {
        if(isEventDeleted){
            dispatch(setConfirmModalData({}))
            dispatch(setViewConfirmModal(false))
        }
    }, [isEventDeleted, dispatch])

    const handleReport = () => {

    } 
    
    const handleEdit = () => { 
        dispatch(setViewEventModalEdit({
                index: props.index,
                data: props.data
        }))
    } 

    const handleSave = () => { 
        dispatch(saveEvent({
            index: props.index,
            eventId: props.data._id,
        }))
    } 

    const handleEventClick = () => {  
        if(isDotsDropped){
            return
        }
        dispatch(setSinglePageEventData({
            eventData: props.data,

            churchInfo: {
                _id: props.data.churchId,
                fullName: profileChurch.fullName,
                profileImg: profileChurch.profileImg
            }
        }))
 
        navigate(`/events/${props.data._id}`)
    }

    const handleEventHover = (hover) => {
        if(hover === true && props.data.discussionEnabled){
            setIsEventHovered(hover)
        }else if(hover === false && isEventHovered === true ){
            setIsEventHovered(false)
        }else{

            // do nothing, no need to rerender anything

        }
    }

    console.log({
        date: props.data.startDate,
        startTime: props.data.startTime,
    })

    return (
        <EventItemContainer
            onMouseEnter={() => handleEventHover(true)}
            onMouseLeave={() => handleEventHover(false)}
            isHovered = {isEventHovered}
            onClick={handleEventClick}
            > 
            <EventImageWrap> 

                <EventImage 
                    onContextMenu={(e) => e.preventDefault()}
                    isHovered = {isEventHovered}
                    src={imageSource}
                    alt={'Event'}
                    style={{
                        filter: blur ? 'blur(2px)' : 'none',
                        transition: blur ? 'none' : 'filter 0.3s ease-out',
                    }}
                />

                {(user.isOrganization || admin[profileChurch._id]) && ( 

                    <>
                        <DotsButton
                            ref={dotsRef}
                            onClick={handleDotsDropdown}
                            ><BsThreeDots />
                        </DotsButton>
                        {
                            isDotsDropped && (
                                <DotsWrap
                                    top='25px'
                                    right='15px'
                                    >
                                    { (user.isOrganization || admin[profileChurch._id]) && (
                                        <>
                                            <DotEle style={{color: 'red'}} onClick={handleDelete}> <DeleteOutlineIcon style={{marginRight: '10px'}} /> Delete</DotEle>
                                            <DotEle onClick={handleEdit}> <EditIcon style={{marginRight: '10px'}} /> Edit</DotEle>
                                        </>
                                    )} 
                                </DotsWrap>
                            )
                        }
                    </>
                )}
                
            </EventImageWrap> 
            <EventItemWrap>
                    <EventDetails>
                        <EventName>{props.data.eventName}</EventName> 
                        { eventEndDate ? 
                            (
                                <EventDateWithEnd>  
                                    <DateDay>{theEventDate.toDateString()}</DateDay>
                                </EventDateWithEnd>
                            )
                            :
                            (
                                <EventDate> 
                                    <DateDay>{theEventDate.toDateString()}</DateDay>  
                                </EventDate>
                            )
                        }  
                    </EventDetails>

                    {
                        props.data.allowRsvp && (
                    
                            <InterestWrap > 
                                RSVP
                            </InterestWrap>
                        )
                    }
            </EventItemWrap>
        </EventItemContainer>
    )
}

const EventItemContainer = styled.div`
    cursor: ${props => props.isHovered ? 'pointer' : 'auto'};
    display: flex;
    max-width: 31%;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
    height: fit-content;
    margin: 10px 10px 10px 0px;
    border-radius: 10px;
    width: fit-content;
    transition: all 0.4s ease-in-out;
    background-color: ${props => props.isHovered ? '#cfcfcf3c' : 'white'};
    box-shadow: 0 0 10px #ccc;
    margin-bottom: 20px;
    position: relative;
    @media (max-width: 768px) { 
        justify-self: center;
        align-self: center;
        padding: 0px;
        width: 100%; 
        margin: 0px;
        flex: unset;
        margin: 0px;
        max-width: unset;
        height: fit-content; 
        justify-content: flex-start; 
        margin-bottom: 20px;

    }
`

const EventImageWrap = styled.div`
    width: 100%;
    height: 150px;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px; 
    position: relative;
    //remove scroll bar
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        background: transparent;

    }

    & img {
        width: 100%;
        height: 150px;
        border-radius: 10px 10px 0px 0px;
        object-fit: cover;
    }

    @media (max-width: 768px) {
        border-radius: 8px;
        align-self: center;
        width: 100%;
        height: fit-content;
        max-height:unset; 
    }

`

const EventImage = styled.img`
    transition: all 0.4s ease-in-out;
    opacity: ${props => props.isHovered ? '0.5' : '1'};
`

const EventItemWrap = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    height: 45%;
    padding: 20px;  
    position: relative;

    @media (max-width: 768px) {
        padding-left: 0px;
        min-height: unset;
        height: fit-content;
    }
`
const EventDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    
    @media (max-width: 768px) {
        width: 100%;
        padding-left: 10px;
    }
`

const EventDate = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: smaller;
    color: #b90000;
    margin-top: 10px;
`

const DateDay = styled.div`
    margin-right: 5px;
`
const DateTime = styled.div`
    margin-left: 5px;
`

const EventDateWithEnd = styled.div`
margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: smaller;
    color: #b90000;
`

const EventName = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
`
const InterestWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    text-align: right;
    margin-top: 10px;
    word-wrap: break-word;
    color: #8b8b8b;
    font-size: 14px;
    position: absolute;
    right: 15px;
    top: 1px;
    font-style: italic;

    @media (max-width: 768px) {
        align-items: stretch;
        padding-top: 10px;
        padding-left: 12px;
        height: fit-content;
    } 
`
 
