import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import postService from './postService'

const initialState = {
  posts: [],

  myPostsPage: null,

  createPostType: '',
  createPostPostingTo: null, //church object

  isPostError: false,
  isPostSuccess: false,
  isPostLoading: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  postsMessage: '',

  isPostCreatedSuccess: false,
  isPostCreatedLoading: false,

  postDeleted: '',
  isPostDeletedSuccess: false,
  isPostDeletedLoading: false,
  
  isPostSaving: false,
  isPostSaved: false,
  isPostSavedSuccess: false,

  isPostLikeLoading: false,
  isPostLikeSuccess: false,

  isPostPrayingLoading: false,
  isPostPrayingSuccess: false,

  viewCreatePostModal: false,

  message: '',
  totalPosts: 0,
  totalPages: 0,
  hasPrevPage: false,
  nextPage:null,
  currentPage: 1,
  prevPage: null,  
  hasNextPage: false,
  
  hasFilter: false,
  filterValue: null,
  hasSortValue: null,
  sortValue: null,

  searchValue: '',
  
  searchPosts: [],
  isSearchPostsLoading: false,
  isSearcPostsSuccess: false,
  isSearchPostsError: false,
  errorField: '',

  userProfilePostsView: 'my-posts', 
  testResetPostLoadingState: true,

  /// delete in production
  testPostOpenAiLoading: false,
  testPostOpenAiSuccess: false,
  testPostOpenAiError: false,
  testPostOpenAiMessage: '',

  selectedPostToViewPrayersUserList: [],

  selectedPostToViewPrayers: {
    postId: '',
    usersPraying: []
  },

  viewWhoIsPrayingModal: false,
  usersPraying: [],
  seeUsersPrayingLoading: false,
  seeUsersPrayingSuccess: false,
  seeUsersPrayingError: false,
  seeUsersPrayingMessage: '',

}

const errorMessage = (error) => {
  return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}

// Create new post
export const setPost = createAsyncThunk(
  'posts/setPost',
  async (postData, thunkAPI) => {
    try {
      
      const token = thunkAPI.getState().auth.user.token
      return await postService.setPost(postData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Create new post
export const testPostOpenAi = createAsyncThunk(
  'posts/testPostOpenAi',
  async (postData, thunkAPI) => {
    try {
      
      const token = thunkAPI.getState().auth.user.token
      return await postService.testPostOpenAi(postData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Create new post
export const setPostOrganization = createAsyncThunk(
  'posts/setPostOrganization',
  async (postData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await postService.setPostOrganization(postData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Create new post
export const setComment = createAsyncThunk(
  'posts/setComment',
  async (commentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await postService.setComment(commentData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user posts
export const getMyPosts = createAsyncThunk(
  'posts/getMyPosts',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await postService.getMyPosts(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// Get Organization Posts
export const getOrganizationPosts = createAsyncThunk(
  'posts/getOrganizationPosts',
  async (organization, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await postService.getOrganizationPosts(organization, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// Get profile Posts
export const getProfilePosts = createAsyncThunk(
  'posts/getProfilePosts',
  async (postData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await postService.getProfilePosts(postData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete user post
export const deletePost = createAsyncThunk(
  'posts/delete',
  async (postData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await postService.deletePost(postData, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// like post
export const likePost = createAsyncThunk(
  'posts/like',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await postService.likePost(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// like post
export const unlikePost = createAsyncThunk(
  'posts/unlike',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await postService.unlikePost(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// like post
export const savePost = createAsyncThunk(
  'posts/save',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await postService.savePost(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const seeWhoIsPraying = createAsyncThunk(
  'posts/seeWhoIsPraying',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await postService.seeWhoIsPraying(data, token)
    } catch (error) {
      const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
      postReset: (state) => initialState,

      clearPosts: (state, action) => {
        state.posts = []
      },

      setFetchPostAttributes: (state, action) => {
        // will be either filter or sort
        state[action.payload.type] = action.payload.value
      },

      setCreatePostType: (state, action) => {
        state.createPostType = action.payload
      },
      setCreatePostPostingTo: (state, action) => {
        state.createPostPostingTo = action.payload
      },

      resetViewPrayingState: (state) => { 
        state.usersPraying = []
        state.seeUsersPrayingLoading = false
        state.isPostPrayingLoading = false
        state.isPostPrayingSuccess = false
        state.selectedPostToViewPrayersUserList = []
      },

      setMyPostsPage: (state, action) => {
        state.myPostsPage = action.payload
      },
      postResetSaved: (state) => {
        state.isPostSaved = false
      },
      setViewCreatePostModal: (state, action) => {
        state.viewCreatePostModal = action.payload
      },
      setSelectPostToViewPrayers: (state, action) => {
        state.selectedPostToViewPrayers.id = action.payload.id
        state.selectedPostToViewPrayers.usersPraying = action.payload.usersPraying
      },
      setViewWhoIsPrayingModal: (state, action) => {
        state.viewWhoIsPrayingModal = action.payload
      },
      postCreateReset: (state, action) => {
        state.isPostCreatedSuccess = false
        state.isPostCreatedLoading = false
        state.isPostError = false
        state.message = '' 
      },
      setErrorField: (state, action) => {
        state.errorField = action.payload
      },
      resetPostLoadingState: (state, action) => {
        // Set to false the state variables in the payload array of strings

        action.payload.valuesToResetArray.forEach((item) => {
          state[item] = false
        })

      },
      setPostsView: (state, action) => {
        state.userProfilePostsView = action.payload
      }
    },
  extraReducers: (builder) => {
    builder
      .addCase(setPostOrganization.pending, (state) => {
        state.isPostLoading = true
      })
      .addCase(setPostOrganization.fulfilled, (state, action) => {
        state.isPostLoading = false
        state.isPostSuccess = true
        state.posts.unshift(action.payload)
      })
      .addCase(setPostOrganization.rejected, (state, action) => {
        state.isPostLoading = false
        state.isPostError = true
        state.message = action.payload
      })
      //---------------------------------------------------   

      .addCase(setPost.pending, (state) => {
        state.isPostCreatedLoading = true
        state.isPostCreatedSuccess = false
      })
      .addCase(setPost.fulfilled, (state, action) => {
        state.isPostCreatedLoading = false
        state.isPostSuccess = true
        state.isPostCreatedSuccess = true
        let post = action.payload.post
        post.user = action.payload.userProfile
        state.posts.unshift(post)
      })
      .addCase(setPost.rejected, (state, action) => {
        state.isPostCreatedLoading = false
        state.isPostError = true
        state.message = action.payload
      })

      //---------------------------------------------------
      .addCase(setComment.pending, (state) => {
        state.isPostLoading = true
      })
      .addCase(setComment.fulfilled, (state, action) => {
        state.isPostLoading = false
        state.isPostSuccess = true
        state.posts.push(action.payload)
        //maybe add comment into posts.comments?
      })
      .addCase(setComment.rejected, (state, action) => {
        state.isPostLoading = false
        state.isPostError = true
        state.message = action.payload
      })

      //---------------------------------------------------

      .addCase(deletePost.pending, (state) => {
        state.isPostDeletedLoading = true
        state.isPostDeletedSuccess = false
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.isPostDeletedLoading = false
        state.isPostDeletedSuccess = true
        state.posts = state.posts.filter(
          (post) => post._id !== action.payload.id
        )
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.isPostDeletedLoading = false
        state.isPostError = true
        state.message = action.payload
      })

      //---------------------------------------------------

      .addCase(getProfilePosts.pending, (state) => {
        state.isPostLoading = true
      })
      .addCase(getProfilePosts.fulfilled, (state, action) => {
        state.isPostLoading = false
        state.isPostSuccess = true



        if(action.payload.pageNumber === 1) {
          state.posts = action.payload.posts
        }else{
          state.posts.push(...action.payload.posts)
        }

        // state.posts.push(...action.payload.posts)
        state.totalPosts = action.payload.totalPosts
        state.totalPages = action.payload.totalPages
        state.currentPage = action.payload.pageNumber
        state.hasNextPage = action.payload.hasNextPage
        state.prevPage = action.payload.previousPage
        state.nextPage = action.payload.nextPage
        state.filterValue = action.payload.filter
        state.sortValue = action.payload.sortValue
      })
      .addCase(getProfilePosts.rejected, (state, action) => {
        state.isPostLoading = false
        state.isPostError = true
        // state.message = action.payload
        state.postsMessage = action.payload
      })
     
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

      .addCase(getMyPosts.pending, (state) => {
        state.isPostLoading = true
      })
      .addCase(getMyPosts.fulfilled, (state, action) => {
        state.isPostLoading = false
        state.isPostSuccess = true
        state.posts = action.payload
      })
      .addCase(getMyPosts.rejected, (state, action) => {
        state.isPostLoading = false
        state.isPostError = true
        state.message = action.payload
      })
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
      .addCase(likePost.pending, (state) => {
        state.isPostLikeLoading = true
        state.isPostLikeSuccess = false

      })
      .addCase(likePost.fulfilled, (state, action) => {
        state.isPostLikeLoading = false
        state.isPostLikeSuccess = true
        state.posts[action.payload.index] = action.payload.updatedPost
      })
      .addCase(likePost.rejected, (state, action) => {
        state.isPostLikeLoading = false
        state.isError = true
        state.message = action.payload
      })
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
      .addCase(unlikePost.pending, (state) => {
        state.isPostLikeLoading = true
        state.isPostLikeSuccess = false
      })
      .addCase(unlikePost.fulfilled, (state, action) => {
        state.isPostLikeLoading = false
        state.isPostLikeSuccess = true
        state.posts[action.payload.index] = action.payload.updatedPost
      })
      .addCase(unlikePost.rejected, (state, action) => {
        state.isPostLoading = false
        state.isPostError = true
        state.message = action.payload
      })
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
      .addCase(savePost.pending, (state) => {
        state.isPostSaving = true
      })
      .addCase(savePost.fulfilled, (state, action) => {
        state.isPostSaving = false
        state.isPostSaved = true
      })
      .addCase(savePost.rejected, (state, action) => {
        state.isPostError = true
        state.message = action.payload
        
      })
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    .addCase(testPostOpenAi.pending, (state) => {
      state.testPostOpenAiLoading = true
    })
    .addCase(testPostOpenAi.fulfilled, (state, action) => {
      state.testPostOpenAiLoading = false
      state.testPostOpenAiSuccess = true
      state.testPostOpenAiMessage = action.payload
    })
    .addCase(testPostOpenAi.rejected, (state, action) => {
      state.testPostOpenAiLoading = false
      state.testPostOpenAiError = true
      state.testPostOpenAiMessage = action.payload 
    })
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    .addCase(seeWhoIsPraying.pending, (state) => {
      state.seeUsersPrayingLoading = true
    })
    .addCase(seeWhoIsPraying.fulfilled, (state, action) => {
      state.seeUsersPrayingLoading = false
      state.seeUsersPrayingSuccess = true
      state.usersPraying = action.payload.usersPraying
    })
    .addCase(seeWhoIsPraying.rejected, (state, action) => {
      state.seeUsersPrayingLoading = false
      state.seeUsersPrayingError = true
      state.seeUsersPrayingMessage = action.payload
    })
  },
})

export const { 
  postCreateReset, 
  postReset, 
  setFetchPostAttributes, 
  clearPosts, 
  setMyPostsPage, 
  setViewCreatePostModal, 
  setErrorField, 
  postResetSaved,
  setPostsView,
  resetPostLoadingState,
  setCreatePostType,
  setCreatePostPostingTo,
  setViewWhoIsPrayingModal,
  setSelectPostToViewPrayers,
  resetViewPrayingState
} = postSlice.actions
export default postSlice.reducer
