import React from 'react'
import styled from 'styled-components'
import { imgUrl } from 'features/constants';
import TimeAgo from 'timeago-react'; 
import useToggleDropdown from 'hooks/useToggleDropdown';
import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { deleteAnnouncement } from 'features/anouncements/announcementSlice';
import useImageLoader from 'hooks/useImageLoader';
import DropDownContainer from 'components/dropdown/DropDownContainer';
import { DropDownListItemDiv } from 'components/dropdown/dropdown.styled';

export default function AncmtItem(props) {
  const dispatch = useDispatch()
  const ref1 = useRef(null)
  const [isDropped, setIsDropped] = useToggleDropdown(ref1)
  const handleDropDown = () => { setIsDropped.toggle(ref1) }
  const { profileChurch } = useSelector((state) => state.profileChurch)
  const { user, admin } = useSelector((state) => state.auth)
  const { isAnnouncementDeletedLoading } = useSelector((state) => state.announcement)
  const [imageSource, { blur }] = useImageLoader(`${imgUrl}/anouncements/${props.data.image ? props.data.image.imagePath1 : null}`, `${imgUrl}/anouncements/${props.data.image ? props.data.image.imagePath2 : null}`)
  
  const handleDelete = () => { 
    dispatch(deleteAnnouncement({ 
      id: props.data._id,
      index: props.index
    })) 
  }

  //  let profileImageToShow;
  //  try{ profileImageToShow =  `${imgUrl}/profile/${props.data.creator.profileImg.imagePath2}`}
  //  catch(e){  profileImageToShow =  null}

  let newDate = new Date(props.data.createdAt)

  return (
    <AnouncementItemContainer>  
      <div className="postItemTop">

          
          <AnouncementTop>  
            <h3>Anouncement</h3>  
            <div> <TimeAgo datetime={newDate}/> by {props.data.author.fullName} </div> 
          </AnouncementTop>


          {/* here we should add an option for the users to hide the announcement for them */}

          {/* <div className="postItemDots" ref={ref1}>
          <div className="postItemDotsDots" onClick={handleDropDown}><MoreHorizIcon/></div>
            {isDropped && (
              <DropDownContainer> 
                {(props.data.creator === user._id || props.data.creator._id || admin[profileChurch._id]) && (
                    <DropDownListItemDiv 
                      disabled={isAnnouncementDeletedLoading}
                      style={{color: 'red'}}
                      onClick={handleDelete}
                      > 
                      <DeleteOutlineIcon className='postItemDotsIcon' /> { isAnnouncementDeletedLoading ? 'Deleting':'Delete'}
                    </DropDownListItemDiv>
                  )}
                </DropDownContainer>
              )}
            </div>*/}
          </div> 

        <PostItemMiddle
          hasImage = {props.data.image}
          >
          { props.data.image && (
            <PostImageWrap>
              <img
                className="postItemImageImg" 
                src={imageSource} 
                alt="announcement"
                style={{
                  borderRadius: '10px',
                  filter: blur ? 'blur(2px)' : 'none',
                  transition: blur ? 'none' : 'filter 0.3s ease-out',
                }}
                /> 
            </PostImageWrap>
          )}
        <PostTextWrap
          hasImage={props.data.image}
          >
          
          {/* break up the text detecting new paragraphs */}
          {props.data.announcementText.split('\n').map((item, i) => {
            return <section key={i}>{item}</section>
          })}

        </PostTextWrap>
      </PostItemMiddle>
    </AnouncementItemContainer>
  )
}

const AnouncementTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: var(--lightThemeFontColor);
  width: 100%;
`

const AnouncementItemContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: white;
  margin: 0;
  padding: 20px 20px 10px 20px; 
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
  border: 1px solid rgb(209, 209, 209);
  border-radius: 10px;
  color: #555;
  z-index: 0;
  margin-bottom: 10px;
  height: fit-content;
  padding-bottom: 10px;

  @media (max-width: 768px) { 
    max-width: unset;
    width: 100%;
    align-self: center;
    justify-self: center;
    margin-bottom: 10px;
    padding-right: 0px;
    margin-left: 0px;  
    padding: 10px 10px 10px 10px; 
  } 
`

const PostItemMiddle = styled.div`
  width: 100%; 
  margin-top: 20px; 
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  
  @media (max-width: 768px) {  
    flex-direction: ${props => props.hasImage ? 'column-reverse': 'column'};
  
  } 
`

const PostTextWrap = styled.div`
  margin-top: ${props => props.hasImage ? '0px':'10px'};
  font-size: 18px;
  display: flex;
  flex-direction: column;
 
  width: 100%;
  padding: 10px;
  padding-right: 20px;
  color: var(--lightThemeFontColor);

  @media (max-width: 768px) { 
    
  }

  section{
    margin-bottom: 10px;
  }

`
const PostImageWrap = styled.div`
  
  
  @media (max-width: 768px) { 

    width: 100%;
  } 

`