import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import videoService from './videoService'
import { set } from 'lodash'

const errorMessage = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
  }

const initialState = {
  videos: [],

  videoUrl: null,

  isVideoUploading: false,
  isVideoUploadSuccess: false,
  isVideoUploadError: false,

  isVideoUdating: false,
  isVideoUpdateSuccess: false,
  isVideoUpdateError: false,

  isDeleteVideoLoading: false,
  isDeleteVideoSuccess: false,

  getVideosLoading: false,
  getVideosSuccess: false,
  getVideosError: false,

  uploadVideoType: null,
  viewUploadVideoModal: false,
  thumbnailPreview: null,
  selectedThumbnail: null,

  selectedVideo: null,
  videoTitle: '',
  viewVideoPlayerModal: false,
  currentVideo: null,

  totalVideos: 0,
  totalPages: 0,
  hasPrevPage: false,
  nextPage:null,
  currentPage: 1,
  prevPage: null,  
  hasNextPage: false,
  videoSort: null,
  searchValue: '',
  
  searchVideos: [],
  isSearchVideosLoading: false,
  isSearcVideosSuccess: false,
  isSearchVideosError: false,
  isSearchActive: false,
  searchVideosTemp: [],

  videoToFetchId: null,
  getCurrentVideoLoading: false,
  getCurrentVideoSuccess: false,
  getCurrentVideoError: false,

  viewEditVideoModal: false,
  videoData: null,

  videoUpdateLoading: false,
  videoUpdateSuccess: false,
  videoUpdateError: false,



} 

export const uploadVideo = createAsyncThunk(
    'video/upload',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await videoService.uploadVideo(data, token)
      } catch (error) {
      const message = errorMessage(error)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

export const getVideos = createAsyncThunk(
  'video/getVideos',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await videoService.getVideos(data, token)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  })

export const deleteVideo = createAsyncThunk(
  'video/deleteVideo',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await videoService.deleteVideo(data, token)
    } catch (error) {
    const message = errorMessage(error)
    return thunkAPI.rejectWithValue(message)
    }
  })

export const updateVideo = createAsyncThunk(
  'video/updateVideo',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await videoService.updateVideo(data, token)
    } catch (error) {
    const message = errorMessage(error)
    return thunkAPI.rejectWithValue(message)
    }
  })

  export const searchVideos = createAsyncThunk(
    'video/searchVideos',
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            return await videoService.searchVideos(data, token)
        } catch (error) {
            const message = errorMessage(error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getCurrentVideo = createAsyncThunk(
  'video/getCurrentVideo',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token

      return await videoService.getCurrentVideo(data, token)
    } catch (error) {
    const message = errorMessage(error)
      return thunkAPI.rejectWithValue(message)
    }
  })

export  const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {

      setUploadVideoType: (state, action) => {
        state.uploadVideoType = action.payload
      },

      setVideoUrl: (state, action) => {
        state.videoUrl = action.payload
      },

      setVideoTitle: (state, action) => {
        state.videoTitle = action.payload
      },

      setViewUploadVideoModal: (state, action) => {
        state.viewUploadVideoModal = action.payload
      },

      setSelectedVideo: (state, action) => {
        state.selectedVideo = action.payload
      },

      setThumbnailPreview: (state, action) => {
        state.thumbnailPreview = action.payload
      },

      setViewVideoPlayerModal: (state, action) => {
        state.currentVideo = action.payload.videoUrl
        state.viewVideoPlayerModal = action.payload.view
        state.videoToFetchId = action.payload.videoId ? action.payload.videoId : null
      },

      chooseThumbnailImage: (state, action) => {
        state.selectedThumbnail = action.payload
      },
      setSortVideo: (state, action) => {
        if(state.videoSort === 'ascending'){
          state.videoSort = 'descending'
        }
        else{
          state.videoSort = 'ascending'
        }
      },
      resetSearchVideos: (state) => {
        state.videos = state.searchVideosTemp
        state.searchVideosTemp = []
        state.isSearchActive = false
      },
      setViewEditVideoModal: (state, action) => {
        state.viewEditVideoModal = action.payload
      },
      setVideoData: (state, action) => {
        state.videoData = action.payload
      },

    },
    extraReducers: (builder) => {
      builder
        .addCase(uploadVideo.pending, (state) => {
          state.isVideoUploading = true
          state.isVideoUploadSuccess = false
          state.isVideoUploadError = false
        })
        .addCase(uploadVideo.fulfilled, (state, action) => {
          state.isVideoUploading = false
          state.isVideoUploadSuccess = true
          state.isVideoUploadError = false
          state.videos.push(action.payload.video)
        })
        .addCase(uploadVideo.rejected, (state, action) => {
          state.isVideoUploading = false
          state.isVideoUploadSuccess = false
          state.isVideoUploadError = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getVideos.pending, (state) => {
          state.getVideosLoading = true
          state.getVideosSuccess = false
          state.getVideosError = false
        })
        .addCase(getVideos.fulfilled, (state, action) => {
          state.getVideosLoading = false
          state.getVideosSuccess = true
          state.getVideosError = false
          state.videos = action.payload.videos
        })
        .addCase(getVideos.rejected, (state, action) => {
          state.getVideosLoading = false
          state.getVideosSuccess = false
          state.getVideosError = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(deleteVideo.pending, (state) => {
          state.isDeleteVideoLoading = true
        })
        .addCase(deleteVideo.fulfilled, (state, action) => {
          state.isDeleteVideoLoading = false
          state.isDeleteVideoSuccess = true
          state.videos = state.videos.filter(video => video._id !== action.payload.videoId)
        })
        .addCase(deleteVideo.rejected, (state, action) => {
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(updateVideo.pending, (state) => {
          state.videoUpdateLoading = true
          state.videoUpdateSuccess = false
          state.videoUpdateError = false
        })
        .addCase(updateVideo.fulfilled, (state, action) => {
          state.videoUpdateLoading = false
          state.videoUpdateSuccess = true
          state.videoUpdateError = false

          let index = state.videos.findIndex(video => video._id === action.payload.video._id  )

          if(index !== -1){
            state.videos[index] = action.payload.video 
            state.viewEditVideoModal = false
          }

        })
        .addCase(updateVideo.rejected, (state, action) => {
          state.videoUpdateLoading = false
          state.videoUpdateSuccess = false
          state.videoUpdateError = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(searchVideos.pending, (state) => {
          state.isSearchVideosLoading = true 
        })
        .addCase(searchVideos.fulfilled, (state, action) => {
          state.isSearchVideosLoading = false
          state.isSearcVideosSuccess = true
          
          if(action.payload.videos.length > 0){ 
            state.searchVideosTemp = state.videos
            state.videos = action.payload.videos

            state.isSearchActive = true

          }

        })
        .addCase(searchVideos.rejected, (state, action) => {
          state.isSearchVideosLoading = false
          state.isSearcVideosSuccess = false
          state.isSearchVideosError = action.payload
        })
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        .addCase(getCurrentVideo.pending, (state) => {
          state.getCurrentVideoLoading = true
        })
        .addCase(getCurrentVideo.fulfilled, (state, action) => {
          state.getCurrentVideoLoading = false
          state.getCurrentVideoSuccess = true
          state.currentVideo = action.payload.video.videoUrl
        })
        .addCase(getCurrentVideo.rejected, (state, action) => {
          state.getCurrentVideoLoading = false
          state.getCurrentVideoSuccess = false
          state.getCurrentVideoError = action.payload
        })
    },

})

export const { 
  resetSearchVideos, setSortVideo, chooseThumbnailImage, setVideoTitle, 
  setViewVideoPlayerModal ,setVideoUrl, setUploadVideoType, setViewUploadVideoModal, 
  setSelectedVideo, setThumbnailPreview, setViewEditVideoModal, setVideoData
} = videoSlice.actions

export default videoSlice.reducer

