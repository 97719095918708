
import { useSelector, useDispatch } from 'react-redux'
import CommentItem from './CommentItem'
import Spinner from '../Spinner'
import { getComments } from '../../features/comments/singlePostCommentSlice'
import styled from 'styled-components'
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';


function SinglePostComments(props) {
  const dispatch = useDispatch()
  const postId = props.postId
  const { 
    sPComments, 
    isSPCommentLoading, 
    hasNextPage,
    nextPage,
    totalComments,
   } = useSelector((state) => state.sPComments)
  

  const handleLoadMoreComments = () => {
    if(hasNextPage){
      dispatch(getComments({postId: postId, pageNumber: nextPage}) )
    }
  }


  return (
    <div 
      className='singlePostCommentsContainer' 
      style={{overflowY: "auto", overflowX: "hidden" }}
      >
      { sPComments && sPComments.length>0  ? //&& !isSPCommentError && !isSPCommentLoading
        (
          sPComments.map((commentItem, index) => (
              <CommentItem 
                key={commentItem._id}
                index={index}
                comment={commentItem}
                />  
            )
          )
        ): isSPCommentLoading ?
        (
            <Spinner /> 
        ) 
        :
        (
          <NoComments> 
            <MarkChatUnreadIcon />
            <span>No comments yet</span>
          </NoComments>
        )
      }
      {
        hasNextPage ?
        (
          <LoadMoreBtn onClick={handleLoadMoreComments}>Load more comments</LoadMoreBtn>
        )
        : !hasNextPage && totalComments > 10 ?
        (
          <CommentsEnd>End of comments</CommentsEnd>
        )
        : (!hasNextPage && totalComments === 0) &&
        (
          <div>  </div>
        )
      }
    </div>
  )
}

const LoadMoreBtn = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  width: fit-content;
  padding: 15px;
  background-color: green;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  &:hover{
    background-color: rgb(0, 99, 0);
  }
`
const CommentsEnd = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  width: fit-content;
  padding: 15px;
  background-color: rgb(200, 200, 200);
  border-radius: 5px;
  color: rgb(60, 60, 60);
  pointer-events: none;
`

const NoComments = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  color: #ccc;
  svg{
    margin-right: 10px;
  }
`

export default SinglePostComments