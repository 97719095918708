import {React, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
// import DatePicker from 'react-datepicker'
import { getCoordinates, resetMap, setLocationCoordinates } from 'features/maps/mapSlice' 
import { createEvent, hideEventModal, removeEventImage, updateEvent } from '../../features/events/eventSlice'
import "react-datepicker/dist/react-datepicker.css"; 
import EventNoteIcon from '@mui/icons-material/EventNote';
import { imgUrl } from 'features/constants'
import placeholder from "../../images/directoryImages/default/addImage.png"
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Checkbox from '@mui/material/Checkbox'; 
import { Tooltip } from '@mui/material' 
import GroupsIcon from '@mui/icons-material/Groups';
import PublicIcon from '@mui/icons-material/Public'; 
import ModalCloseButton from 'components/buttons/ModalCloseButton'
import { language } from 'app/language'
import SpinnerSmall from 'components/SpinnerSmall'
import useGetWindowSize from 'hooks/useGetWindowSize'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import MoreTimeIcon from '@mui/icons-material/MoreTime'; 
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { ButttonWrap } from 'components/files/files.styled'
import { SubmitButton } from 'components/giving/giving.styled'

import { BsEnvelope, BsEnvelopeCheck } from "react-icons/bs";
import { BiGroup } from "react-icons/bi";
import { MdPublic } from "react-icons/md";
import { MdOutlineForum } from "react-icons/md";
import { LiaCommentSlashSolid } from "react-icons/lia";


const dateInputStyle = {
  borderColor: "white",
  width: "100%",
  input: { color: '#868686' },
  "& .MuiInputLabel-root.Mui-focused": { 
    color: "#656565" 
  }, //styles the label
  "& .MuiOutlinedInput-root": {
   "&:hover > fieldset": { borderColor: "white" },
   height: "48px",
   borderRadius: "10px",
   backgroundColor: 'aliceblue',
   borderColor: "white"
  },
  ".MuiOutlinedInput-notchedOutline":{
    border: "none",
  }
}

export default function CreateEventModal(props) {
  const dispatch = useDispatch()
 
  const { user } = useSelector((state) => state.auth)
  const { profileChurch } = useSelector((state) => state.profileChurch)
  const { location, getCoordinatesLoading, getCoordinatesSuccess, getCoordinatesMessage, locationCoordinates, searchTerm } = useSelector((state) => state.maps)
  const { isEventLoading, isEventCreatedLoading, isEventCreatedSuccess, eventImagePreview, preselectedDate } = useSelector((state) => state.events)
  const [width, height] = useGetWindowSize()
  const [openStart, setOpenStart] = useState(false)
  const [openEnd, setOpenEnd] = useState(false)
  const [openEndTime, setOpenEndTime] = useState(false)
  const [openStartTime, setOpenStartTime] = useState(false)

  const [addStartTime, setAddStartTime] = useState(false)
  const [addEndTime, setAddEndTime] = useState(false)

  const [startDate, setStartDate] = useState(dayjs( `${dayjs().year()}-${dayjs().month() + 1}-${dayjs().date()}`));
  const [endDate, setEndDate] = useState( dayjs( `${dayjs().year()}-${dayjs().month() + 1}-${dayjs().date() + 1}`));
  const [startTime, setStartTime] = useState( dayjs( `2024-01-17T15:30`));
  const [endTime, setEndTime] = useState( dayjs('2024-01-17T15:30') );


  const [addLocation, setAddLocatiion] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const [eventId, setEventId] = useState('') //used when editing
  const [eventDataEdited, setEventDateEdited] = useState(false)
  const [eventlistIndex, setEventlistIndex] = useState() //used when editing
  const [isImageChanged, setIsImageChanged] = useState(false) //used when editing
  const [imageToShow, setImageToShow] = useState()
  const [hasEndDate, setHasEndDate ] = useState(false)
  const [discussionEnabled, setDiscussionEnabled] = useState(true) 
  const locationRef = useRef(null)
  const [alignment, setAlignment] = useState('members');
  const [locationInput, setLocationInput] = useState('')
  const [inputFocus, setInputFocus] = useState('')
  const [allowRsvp, setAllowRsvp] = useState(false)

  const [{alt, src, edited, fileSrc}, setImg] = useState({
    src: placeholder,
    alt: 'Upload an Image',
    edited: false,
  });

  useEffect(() => {
    if(preselectedDate){
      setStartDate(preselectedDate)
    }
  }, [preselectedDate])
 
  useEffect(() => {
    if(props.isEditing){
      try{  setImageToShow(`${imgUrl}/events/${props.data.image.imagePath2}`) }
      catch(e){ setImageToShow(`${imgUrl}/events/default/eventDefault.jpg}`) }

      // setStartDate(props.data.eventDate)
      setDescription(props.data.description)
      setName(props.data.eventName)
      setEventId(props.data._id)
      setEventlistIndex(props.index)
      setDiscussionEnabled(props.data.discussionEnabled)
      setAlignment(props.data.privacy)
      setLocationInput(props.data.locationAddress)
      setStartDate(dayjs(props.data.startDate))
      setAllowRsvp(props.data.allowRsvp)

      if(props.data.startTime){
        setStartTime(dayjs(`2024-01-17T${props.data.startTime}`))
        setAddStartTime(true)
      }

      if(props.data.endDate){ 
        setEndDate(dayjs(props.data.endDate)) 
        setHasEndDate(true)

      }
      if(props.data.endTime){ 
        setEndTime(dayjs(`2024-01-17T${props.data.endTime}`)) 
        setAddEndTime(true)
      }

      setAddLocatiion(true)

      dispatch(setLocationCoordinates([props.data.coordinates.lng, props.data.coordinates.lat]))
    }
  }, [])

  const handleDate = (date) => {
    if(props.isEditing){
      //because I am not setting the event date from the DB as a variable when user is editing
      //user will have to reset the eventDate so if they are editing and they didn;t reset the event date, they will get a toast notification
      //ideally we want to be able to keep the date from the DB when user is editing since they might not
      //need to edit the date. for now just warn user to readjust the desired date.
      setEventDateEdited(true)
      setStartDate(date)
    }else{
      // setStartDate(date)
      
      // using the data parameter to set the date
      setStartDate(date)
      console.log(date)
      console.log(startDate)

    }
  }

  const handleDescription = (e) => { 
    if(e.target.value.length < 1650){ 

      // setDescription(e.target.value.replace(/\n/g, '<br />'))

      setDescription(e.target.value)
    } 
  }

  const handleEventName = (e) => {
    if(e.target.value.length < 80){ 
      setName(e.target.value)
    } 

  }
  const handleImg = (e) => { 
    if(props.isEditing){
      setIsImageChanged(true)
      let fileName = e.target.files[0].name
      let fileType =  fileName.slice(fileName.lastIndexOf('.')+1).toLowerCase()
      if(fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'png'){  return toast.error('Select a JPG or PNG files only') }
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
        fileSrc: e.target.files[0],
        edited: true
      }); 

    }else{
      let fileName = e.target.files[0].name
      let fileType =  fileName.slice(fileName.lastIndexOf('.')+1).toLowerCase()
      if(fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'png'){  return toast.error('Select a JPG or PNG files only') }

      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
        fileSrc: e.target.files[0],
        edited: true
      }); 
    }
  }

  const removeImage = () => {
    dispatch(removeEventImage())
    setImg({
        src: placeholder,
        fileSrc: '',
        alt: 'Upload an Image',
        edited: false
    });    
  }

  const handleSubmit = () => { 
    
    let startDate0 =  `${startDate.get('year')}-${startDate.get('month') + 1}-${startDate.get('date')} `
    let endDate0 = `${endDate.get('year')}-${endDate.get('month') + 1}-${endDate.get('date')} `
    let startTime0 = `${startTime.get('hour')}:${startTime.get('minute')}`
    let endTime0 = `${endTime.get('hour')}:${endTime.get('minute')}`
 
    const formData = new FormData();

    if(props.isEditing){

      if(name.length === 0){ return(toast.error('Add a name for this event')) }
      if(description.length === 0){ return(toast.error('Add a description for this event')) }

      if(isImageChanged){
        formData.append("file", fileSrc)
        formData.append("imageUpdated", true)
      }
      formData.append("eventId", eventId)
      formData.append("churchId", profileChurch._id)
      formData.append("eventName", name);
      formData.append("startDate", startDate0);

      if(hasEndDate){ 

        formData.append("endDate", endDate); 

        if(addEndTime){
          formData.append("endTime", endTime0)
        }
      }
      formData.append("startTime", startTime0)

      if(!locationInput){ 
        return(toast.error('Add a location for this event')) 
      }

      formData.append("locationAddress", locationInput)

      if(locationCoordinates[0] === null || locationCoordinates[1] === null){ return(toast.error('Add a location for this event')) }
      formData.append('coordinatesLng', locationCoordinates[0])
      formData.append('coordinatesLat', locationCoordinates[1])      
      
      formData.append("discussionEnabled", discussionEnabled)
      formData.append("privacy", alignment)

      if(hasEndDate){ formData.append("eventEndDate", endDate); } 

      if(!description){ return(toast.error('Add a description for this event')) }
      formData.append("description", description)
      formData.append("index", eventlistIndex)
      formData.append("allowRsvp", allowRsvp)

      dispatch(updateEvent(formData))

    }else{

      if(name.length === 0){ return(toast.error('Add a name for this event')) }
      if(description.length === 0){ return(toast.error('Add a description for this event')) }
      if(!eventImagePreview){ return(toast.error('Add an image for this event')) }
      formData.append("file", fileSrc)
      formData.append("churchId", profileChurch._id)
      formData.append("eventName", name);
      formData.append("startDate", startDate);

      if(hasEndDate){ 
        formData.append("endDate", endDate0); 

        if(addEndTime){
          formData.append("endTime", endTime0)
        }
      }
      formData.append("startTime", startTime0)
      if(!locationInput){ 
        return(toast.error('Add a location for this event')) 
      }
      else if(locationInput === "virtual"){
        formData.append('coordinatesLng', 0)
        formData.append('coordinatesLat', 0)
      } else if(locationInput && locationInput !== 'virtual' && (locationCoordinates == null)){ 
        return( 
          toast('Click Set Location to confirm location', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light", 
          })
        )
      }
      else if(
        locationInput 
        && 
        locationInput !== 'virtual' 
        && 
        getCoordinatesSuccess
        && 
        locationCoordinates !== null
      ){
        formData.append('coordinatesLng', locationCoordinates[0])
        formData.append('coordinatesLat', locationCoordinates[1])      
      }
      
      formData.append("locationAddress", locationInput) 
      formData.append("discussionEnabled", discussionEnabled)
      formData.append("privacy", alignment)
      formData.append("allowRsvp", allowRsvp)

      if(hasEndDate){ formData.append("eventEndDate", endDate); } 
      if(!description){ return(toast.error('Add a description for this event')) }
      formData.append("description", description)

      dispatch(createEvent(formData))
    }
  }

  // const isImgPreview =  Object.keys(eventImagePreview).length > 0

  // let eventImageDefualtToShow;
  // try{  eventImageDefualtToShow =  `${imgUrl}/events/default/eventDefault.jpg` }
  // catch(e){ eventImageDefualtToShow = null }

  useEffect(() => {
      if(isEventCreatedSuccess){
        toast.success('New event created!')
        setName('')
        setDescription('')
        dispatch(removeEventImage())
        dispatch(hideEventModal())
      }
  }, [isEventCreatedSuccess, dispatch])




  const handleLocation = (e) => {
    setLocationInput(e.target.value)
  }

  useEffect(() => {
    
    if(getCoordinatesSuccess && location.bestMatch){
        setLocationInput(location.bestMatch)
    }
  }, [getCoordinatesSuccess, location.bestMatch])

  // const handleLocationEnter = () => {
  //   if(searchTerm !== locationInput){ 
  //       if(document.activeElement === locationRef.current){
  //           dispatch(getCoordinates({address: locationInput}))
  //       }
  //   }
  // }

  // var offset = new Date().getTimezoneOffset();
  // console.log(offset);
  // let timeOffSet = offset + (-2*offset);
  // console.log( timeOffSet);

  // // get the utc time
  // var utcDate = new Date();
  // console.log(utcDate);

  // // get the GMT time zone
  // var gmtDate = new Date(utcDate.getTime() + (timeOffSet*60*1000));
  // console.log(gmtDate);

  return ( 
      <EventModalWrap>
        <ModalCloseButton
          handler={() => dispatch(hideEventModal())}
          top="15px"
          right="15px"
          size='35px'
        />        
        
        <CreateEventFormTop>
            <EventNoteIcon style={{  fontSize: '30px', marginRight: '10px'}} />  
            <EventModalHeading> 
              {
                props.isEditing ? 
                (
                  language.updateEvent[user.preferredLanguage]
                )
                :
                (
                  language.createEvent[user.preferredLanguage]
                )
              } 
            </EventModalHeading>
        </CreateEventFormTop>

        <ScrollContainer>

        <ModalForm>
          <AddInput> 
            <input value={name} onChange={handleEventName} maxLength = "120" 
              placeholder={language.eventName[user.preferredLanguage]}
              />
          </AddInput> 
 


          <AddInputDate> 
            <LocalizationProvider dateAdapter={AdapterDayjs}> 
              <DateWrap>
                <DWH>
                  Start Date
                </DWH>
                {
                  width > 768 ?
                  ( 
                    <DesktopDatePicker
                      value={startDate}
                      onChange={handleDate}
                      sx={dateInputStyle}
                      open={openStart}
                      onClose={() => setOpenStart(false)}
                      slotProps={{textField: {onClick: () => setOpenStart(true)},}} 
                      // defaultValue={dayjs( `${dayjs().year()}-${dayjs().month() + 1}-${dayjs().date()}`)} 
                      /> 
                  )
                  :
                  ( 
                    <MobileDatePicker defaultValue={dayjs('2022-04-17')} /> 
                  )
                }                 
              </DateWrap>

              {
                hasEndDate ?
                (
                  <DateWrap>
                    <DWH>
                      End Date
                    </DWH>

                    {
                      width > 768 ?
                        (
                          <DesktopDatePicker
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            sx={dateInputStyle}
                            open={openEnd}
                            onClose={() => setOpenEnd(false)}
                            slotProps={{textField: {onClick: () => setOpenEnd(true)},}} 
                            // defaultValue={dayjs( `${dayjs().year()}-${dayjs().month() + 1}-${dayjs().date() + 1}`)} 
                            /> 
                        )
                        :
                        (
                          <MobileDatePicker defaultValue={dayjs('2022-04-17')} /> 
                        )
                      }
                    </DateWrap>
                )
                :
                (
                  <Ending onClick={() => setHasEndDate(true)}>
                    <DateRangeIcon />
                    {language.endDate[user.preferredLanguage]}
                  </Ending>
                )
              }


            </LocalizationProvider>
            </AddInputDate> 

            <AddInputDate>

              {
                addStartTime ? 
                (
                  <div style={{ width: '30%', }} >
                    <DWH> Start Time </DWH>
                    <LocalizationProvider dateAdapter={AdapterDayjs}> 
                      <DesktopTimePicker
                        value={startTime}
                        onChange={(newValue) => setStartTime(newValue)}
                        sx={dateInputStyle}
                        defaultValue={dayjs('2022-04-17T15:30')} 
                        open={openStartTime}
                        onClose={() => setOpenStartTime(false)}
                        slotProps={{textField: {onClick: () => setOpenStartTime(true)},}}
                        />
                    </LocalizationProvider>         
                  </div> 
                )
                :
                (
                  <DateWrap>
                    <Ending
                      onClick={() => setAddStartTime(true)}
                      >
                      <MoreTimeIcon />
                      Add start time
                    </Ending>
                  </DateWrap>
                )
              } 

              {
                hasEndDate && ( 
                  !addEndTime ? 
                  (
                    <DateWrap>
                      <Ending onClick={() => setAddEndTime(true)} >
                        <MoreTimeIcon /> Add end time
                      </Ending>
                    </DateWrap>              
                  )
                  :
                  (
                    <div style={{  width: '30%' }} >
                    <DWH> End Time </DWH>
                      <LocalizationProvider dateAdapter={AdapterDayjs}> 
                        <DesktopTimePicker
                          value={endTime}
                          onChange={(newValue) => setEndTime(newValue)}
                          sx={dateInputStyle}
                          defaultValue={dayjs('2022-04-17T15:30')} 
                          open={openEndTime}
                          onClose={() => setOpenEndTime(false)}
                          slotProps={{textField: {onClick: () => setOpenEndTime(true)},}}
                          />
                      </LocalizationProvider>                    
                    </div>
                  ) 
                )
              }


            </AddInputDate>

            <AddInput 
              onFocus={
                (e) => e.target.style.height = '200px'
              }
              onBlur={
                (e) => e.target.style.height = '100px'
              }
              style={{marginBottom: '20px'}} >
              <textarea 
                // onFocus={ 
                //   (e) => e.target.style.height = '200px'
                // }
                // onBlur={
                //   (e) => e.target.style.height = '100px'
                // }
                style={{height: '100px', width: '100%', fontSize: '13px'}}
                value={description} onChange={handleDescription}  
                placeholder={language.eventDescription[user.preferredLanguage]}
                />
            </AddInput>

          <MiddleSection>
          { edited ? 
                  (
                    <ImagePlaceholder>  <ImgPreview src={src} alt={alt} /> </ImagePlaceholder >
                  ) : props.isEditing && (
                    <ImagePlaceholder > <ImgPreview src={imageToShow} alt="Add Image" /> </ImagePlaceholder >
                  )
                } 
            <MiddleButtonsWrap> 
              <AddImage>  
                <AddImageBtnsWrap>
                  <CEMLabel>  
                    <AddPhotoAlternateIcon />
                    <span style={{marginLeft: '10px'}}> 
                    { edited ? 
                      (language.replace[user.preferredLanguage])
                      :
                      (language.add[user.preferredLanguage])} 
                      {' ' + language.image[user.preferredLanguage]}
                      </span> 
                  </CEMLabel>
                  <input hidden  
                    id="imageUpload"  
                    type="file" 
                    accept=".png, .jpg, .jpeg" 
                    name="file" onChange={handleImg}/>
                  { edited && ( 
                    <RemoveImage onClick={removeImage}>
                      {language.remove[user.preferredLanguage]}
                    </RemoveImage> 
                  )}
                </AddImageBtnsWrap>
              </AddImage>

              <AddLocationBtn
                onClick={() => setAddLocatiion(true)}
                >
                  <AddLocationAltIcon />
                  <span style={{marginLeft: '10px'}}>
                    {language.addLocation[user.preferredLanguage]}
                  </span>
              </AddLocationBtn>
            </MiddleButtonsWrap>

            {
              addLocation && (
                <AddLocationWrap>
                  <LocationInputWrap>
                    <ListingInput
                      onFocus={() => setInputFocus('location')}
                      ref={locationRef}
                      type="text"
                      value={locationInput}
                      onChange={handleLocation}
                      placeholder={`${language.eventLocation[user.preferredLanguage]} ${inputFocus === 'location' ? '(i.e. zip code, city name, and/or address)': ''}`}
                      />
                    {
                      searchTerm === locationInput || locationInput === location.bestMatch ? 
                      (
                          <button
                            onClick={() => {
                                setLocationInput('')
                                dispatch(resetMap())
                            }}
                            >
                            {
                              language.clear[user.preferredLanguage]
                            }
                          </button>    
                      )
                      :
                      (
                        <>
                        <button
                          style={{
                            marginRight: '10px',
                            backgroundColor: locationInput === 'virtual' ? 'green' : 'white',
                            color: locationInput === 'virtual' ? 'white' : 'grey'
                          }}
                          onClick={() => setLocationInput('virtual')}
                          >
                          Virtual Event
                        </button>
                        <button
                          onClick={() => dispatch(getCoordinates({address: locationInput}))}
                          >
                          {
                            language.setLocation[user.preferredLanguage]
                          }
                        </button>                        
                        </>

                      )
                    }
                </LocationInputWrap> 
              </AddLocationWrap> 
            )}

            <SettingsSection>

              {/* <Discussion>
                <Checkbox 
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                  checked={discussionEnabled} 
                  onChange={() => setDiscussionEnabled(!discussionEnabled)} 
                  />
                <Tooltip 
                  title={language.discussionEnabledDescription[user.preferredLanguage]}
                  placement="top"
                  > 
                  {
                    discussionEnabled ? 
                    ( <span>
                        {language.discussionEnabled[user.preferredLanguage]}
                    </span> )
                    :
                    ( <span>
                        {language.discussionDisabled[user.preferredLanguage]}
                    </span> )
                  }
                </Tooltip>
              </Discussion>    */}
            
              <ToggleButtonContainer>
                <Tooltip
                  title={language.discussionEnabledDescription[user.preferredLanguage]}
                  placement="top"
                  >
                  <MyToggleButton
                    selected={discussionEnabled}
                    onClick={() => setDiscussionEnabled(!discussionEnabled)}
                    >
                    {
                      discussionEnabled ?
                      (
                        <MdOutlineForum />
                      )
                      :
                      (
                        <LiaCommentSlashSolid />
                      )
                    }
                    Forum
                  </MyToggleButton>

                </Tooltip>
              </ToggleButtonContainer>
                  
              <ToggleButtonContainer>
                <Tooltip
                  title={"Allow members to RSVP to this event"}
                  placement="top"
                  >
                  <MyToggleButton
                    selected={allowRsvp}
                    onClick={() => setAllowRsvp(!allowRsvp)}
                    >
                      {
                        allowRsvp ?
                        (
                          <BsEnvelopeCheck />
                        )
                        :
                        (
                          <BsEnvelope />
                        )
                      }
                      RSVP
                  </MyToggleButton>
                </Tooltip> 
                <Tooltip 
                  title={language.membersDescription[user.preferredLanguage]}
                  placement="top"
                  >
                  <MyToggleButton
                    selected={alignment === 'members'}
                    onClick={() => setAlignment('members')}
                    >
                      <BiGroup />
                        {language.members[user.preferredLanguage]} 
                  </MyToggleButton>
                </Tooltip>
                  
                <Tooltip
                  title={language.publicDescription[user.preferredLanguage]}
                  placement="top"
                  >
                  <MyToggleButton 
                    selected={alignment === 'public'}
                    onClick={() => setAlignment('public')}
                    >
                      <MdPublic />
                      {language.public[user.preferredLanguage]} 
                  </MyToggleButton>
                </Tooltip>                
              </ToggleButtonContainer>
            </SettingsSection>
          </MiddleSection>

          <ButttonWrap style={{ marginTop: '40px', marginBottom: '20px' }} >
            {props.isEditing ? 
              (
                <SubmitButton disabled={isEventCreatedLoading} onClick={handleSubmit}>
                { isEventCreatedLoading ? ('Updating...'):('Update Event')}
                </SubmitButton> 
              )
              :
              (
                <SubmitButton 
                  disabled={isEventCreatedLoading} 
                  onClick={handleSubmit}>
                  { isEventCreatedLoading ? 
                    ( 
                      <SpinnerSmall /> 
                    )
                    :
                    (
                      language.createEvent[user.preferredLanguage]
                    )}
                </SubmitButton> 
              )
            }            
          </ButttonWrap> 
        </ModalForm>
      </ScrollContainer>
    </EventModalWrap> 
  )
}

const MiddleButtonsWrap = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

`

const DateWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const DWH = styled.div`
  font-size: 11px;
  color: #999999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & svg{
    font-size: 20px;
    margin-right: 10px;
  }

`

const AddLocationBtn = styled.div`

display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px;
  margin-left: 0px;
  width: fit-content;
  color: var(--lightThemeFontColor);
  background-color: #e7e7e7;
  cursor: pointer;
  font-size: 11px;
  border-radius: 8px;
  &:hover{
      background-color: #e7e7e7;
      
  }

`

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;

  /* remove scrollbar */
  ::-webkit-scrollbar {
      display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

`


const AddLocationWrap = styled.div` 
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;  
  background-color: aliceblue;
  padding: 7px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
`

const EventModalWrap = styled.div`
  z-index: 5;
  width: 700px;
  min-height: fit-content;
  max-height: 90vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 2px;
  position: relative;
  animation: animate 0.3s; 
  padding: 20px;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    top: 0;
    width: 98%; 
    min-height: 50vh; 
    height: fit-content ;
    max-height: 90vh;
    padding: 10px;
  }
`

const EventModalHeading = styled.div`
  font-size: 14px;
  font-weight: 100; 

  @media (max-width: 768px) {
    font-size: 14px;
    padding-top: 0px;

  }
`
const ModalForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-top: 10px;
  width: 100%; 
  textarea{
    resize: none;
    padding: 10px; 
    border: none;
    outline: none;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
  }
  textarea:focus{
    outline: none;
  }
  textarea::placeholder{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
  }
    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        font-size: 13px;
        padding: 5px 9px;
    }

`

const MiddleSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Discussion = styled.div`
  display: flex;
  font-size: 10px;
  width: 100%;
  color: #838181;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`

const CEMLabel = styled.label.attrs(() => ({
  htmlFor: 'imageUpload'
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px;
  margin-left: 0px;
  width: fit-content;
  color: var(--lightThemeFontColor); 
  background-color: #e7e7e7;
  cursor: pointer;
  font-size: 11px;
  border-radius: 8px;
 
`

const AddImageBtnsWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    color: #535353;
`
const RemoveImage = styled.div`
    padding-left: 5px;
    cursor: pointer;
    font-size: 11px;
    color: rgb(80,80,80);
    &:hover{
      text-decoration: underline;
    }
`

const ImagePlaceholder = styled.div`
    width: 100%;
    padding: 5px;
    height: fit-content; 
    margin-bottom: 10px;
    border-radius: 4px;

    & img{
      border-radius: 6px;
      box-shadow: 0px 0px 5px #a6a6a6;
    }

`
const AddImage = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 100%;
    }
`


const ImgPreview = styled.img`
    width: 100%;
    height: auto;
`

const AddInput = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 6px;
    align-items: flex-start;
    justify-content: flex-start; 
    background-color: aliceblue; 
    outline: none;
    font-size: 11px; 
    width: 100%; 
    margin-bottom: 13px;
    transition: all .4s;
    /* height: fit-content; */

    & input{
      transition: all .4s;
      height: 100%;
        color: #706f6f;
        display: flex;
        padding: 5px;
        border: none;
        width: 100%;
        background-color: aliceblue;
        padding: 10px;
        ::placeholder{
        color: var(--lightThemeFontColor);
        font-size: 12px;
      }
    };
    & input:focus{
        outline: none;
    }

    & textarea{
      background-color: aliceblue;

      ::placeholder{
        color: var(--lightThemeFontColor);
        font-size: 12px;
      }
    }

    @media (max-width: 768px) {
        
    }
`

const AddInputDate = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    width: 100%;
    margin-bottom: 20px;
    height: fit-content ;

    & input{
        display: flex;
        padding: 5px;
        border: none;
        width: 100%
    };
    & input:focus{
        outline: none;
    }
    @media (max-width: 768px) {
        padding: 0px;
    }
`

const Ending = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--lightThemeFontColor);
  cursor: pointer;
  padding: 7px 15px;
  height: fit-content ;
  border-radius: 11px;
  transition: all .4s;
  &:hover{
      background-color: #e6e6e6;
    }
`
 
 
const CreateEventFormTop = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--lightThemeFontColor); 
    justify-content: flex-start;    
    margin-bottom: 20px;
`

const LocationInputWrap = styled.div`

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; 

    button{
        width: fit-content;
        height: 30px;
        outline: none;
        padding: 0px 15px;
        border: none;
        font-size: 10px;
        border-radius: 5px;
        background-color: #e0e0e0;
        color: #777777;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover{
            background-color: #cecece;
        }
    }

`

const ListingInput = styled.input`
    width: 100%;
    padding: 5px;
    border: none;
    background-color: aliceblue;
    outline: none; 
`

const SettingsSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
`

const ToggleButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const MyToggleButton = styled.div`
  padding: 8px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 11px;
  cursor: pointer;
  border: 1px solid #e7e7e7;
  background-color: ${props => props.selected ? 'green':'#f0f0f0'};
  color: ${props => props.selected ? 'white':'#b8b8b8'};
  &:hover{
    background-color: ${props => props.selected ? 'green':'#e7e7e7'};
    border: 1px solid ${props => props.selected ? 'green':'#e7e7e7'};
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  svg{
    margin-right: 10px;
    font-size: 25px;
  }
`